import React from 'react'
import { string } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Typography } from '@creditas-ui/react'
import { DocumentSheet, Target, HandshakeSquare } from 'icons'
import {
  Container,
  DefContainer,
  DefinitionContainer,
  DescriptionDefinitionContainer,
  ImageContainer,
  ItemListContainer,
  LeftContainer,
  ListHeaderDefinitionContainer,
  RightContainer,
  TitleDefinitionContainer,
} from './definition.styles'

const propTypes = {
  title: string,
  description: string,
  image: string,
  altText: string,
}

const Definition = ({ title, description, image, altText }) => {
  const { t } = useTranslation()
  const itemImages = [DocumentSheet, Target, HandshakeSquare]
  const itemsList = t('home:definition:items', { returnObjects: true })
  return (
    <Container>
      <DefinitionContainer>
        <DefContainer>
          <LeftContainer>
            <ImageContainer>
              <img src={image} alt={altText} />
            </ImageContainer>
          </LeftContainer>
          <RightContainer>
            <TitleDefinitionContainer>
              <Typography
                component="h2"
                variant="headingLgLight"
                color="neutral.90"
                dangerouslySetInnerHTML={{
                  __html: title,
                }}
              />
            </TitleDefinitionContainer>
            <DescriptionDefinitionContainer>
              <Typography
                component="p"
                variant="bodyMdRegular"
                color="neutral.80"
              >
                {description}
              </Typography>
            </DescriptionDefinitionContainer>
            <ListHeaderDefinitionContainer>
              <Typography
                component="p"
                variant="bodyMdRegular"
                color="neutral.80"
              >
                {t('home:definition:listHeader')}
              </Typography>
            </ListHeaderDefinitionContainer>
            {itemsList.map((item, index) => {
              return (
                <ItemListContainer key={item.text}>
                  {itemImages[index]()}
                  <Typography
                    component="p"
                    variant="bodyMdRegular"
                    color="neutral.90"
                  >
                    {item.text}
                  </Typography>
                </ItemListContainer>
              )
            })}
          </RightContainer>
        </DefContainer>
      </DefinitionContainer>
    </Container>
  )
}

Definition.propTypes = propTypes

export default Definition
