import { styled, media } from '@creditas-ui/react'
import { LazyImage } from 'components/LazyImage'

const Container = styled.section`
  background: #ffff;
  width: 100vw;
`

const DefinitionContainer = styled.div`
  margin: 0 auto;
  padding: 76px 0 0;
  max-width: 1366px;
  display: grid;
  grid-template-columns: 638px 1fr;
  grid-template-rows: auto;
  column-gap: 90px;
  grid-template-areas: 'image definition';

  ${media.down('5xl')} {
    padding: 76px 20px 0;
    grid-template-columns: 1fr;
    grid-template-areas:
      'definition'
      'image';
  }
  ${media.between('5xl', '8xl')} {
    margin: 0 71px;
  }
`

const LeftContainer = styled.div`
  grid-area: image;
`

const ImageContainer = styled(LazyImage)`
  width: 100%;
  height: 310px;
  border-radius: 15px;
  ${media.down('xs')} {
    height: 250px;
  }
  ${media.between('xl', '2xl')} {
    height: 385px;
  }
  ${media.up('2xl')} {
    max-width: 638px;
    height: 536px;
  }
`

const RightContainer = styled.div`
  grid-area: definition;

  ${media.up('5xl')} {
    margin: 42px 0 0 0;
  }
`

const TitleDefinitionContainer = styled.div`
  margin: 0 0 24px 0;
`

const DescriptionDefinitionContainer = styled.div`
  margin: 0 0 24px 0;
`

const ListHeaderDefinitionContainer = styled.div`
  margin: 0 0 28px 0;
`

const ItemListContainer = styled.div`
  :last-child {
    padding: 0;
  }

  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 0 28px 0;

  svg {
    margin: 0 18px 0 0;
    width: 32px;
    height: 32px;
  }
`

export {
  Container,
  DefinitionContainer,
  LeftContainer,
  ImageContainer,
  RightContainer,
  TitleDefinitionContainer,
  DescriptionDefinitionContainer,
  ListHeaderDefinitionContainer,
  ItemListContainer,
}
