import React from 'react'
import { string } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { Typography } from '@creditas-ui/react'
import { DocumentSheet, Target, HandshakeSquare } from 'icons'
import {
  Container,
  DefinitionContainer,
  DescriptionDefinitionContainer,
  ImageContainer,
  ItemListContainer,
  LeftContainer,
  ListHeaderDefinitionContainer,
  RightContainer,
  TitleDefinitionContainer,
} from './definition.styles'

const propTypes = {
  text: string,
  imageDesktop: string,
  imageMobileOrTablet: string,
}

const Definition = ({ text, imageDesktop, imageMobileOrTablet }) => {
  const { t } = useTranslation()
  const isMobileOrTablet = useMediaQuery(
    { maxDeviceWidth: 1024 },
    { deviceWidth: 1024 },
  )
  const itemIcons = [DocumentSheet, Target, HandshakeSquare]
  const itemsList = t('mx:definition:items', { returnObjects: true })
  return (
    <Container>
      <DefinitionContainer>
        <LeftContainer>
          <ImageContainer
            src={isMobileOrTablet ? imageMobileOrTablet : imageDesktop}
            alt={text}
            aspectRatio={2}
          />
        </LeftContainer>
        <RightContainer>
          <TitleDefinitionContainer>
            <Typography
              component="h2"
              variant="headingLgLight"
              color="neutral.90"
              dangerouslySetInnerHTML={{
                __html: t('mx:definition:title'),
              }}
            />
          </TitleDefinitionContainer>
          <DescriptionDefinitionContainer>
            <Typography
              component="p"
              variant="bodyMdRegular"
              color="neutral.80"
            >
              {t('mx:definition:description')}
            </Typography>
          </DescriptionDefinitionContainer>
          <ListHeaderDefinitionContainer>
            <Typography
              component="p"
              variant="bodyMdRegular"
              color="neutral.80"
            >
              {t('mx:definition:listHeader')}
            </Typography>
          </ListHeaderDefinitionContainer>
          {itemsList.map((item, index) => {
            return (
              <ItemListContainer key={item.text}>
                {itemIcons[index]()}
                <Typography
                  component="p"
                  variant="bodyMdRegular"
                  color="neutral.90"
                >
                  {item.text}
                </Typography>
              </ItemListContainer>
            )
          })}
        </RightContainer>
      </DefinitionContainer>
    </Container>
  )
}

Definition.propTypes = propTypes

export default Definition
