import { styled, media } from '@creditas-ui/react'

const Container = styled.section`
  background: #ffff;
  width: 100%;
`

const DefinitionContainer = styled.div`
  padding: 0 20px 48px 20px;
  margin: 0 auto;
  max-width: 1366px;
  display: flex;
  flex-direction: column;

  ${media.up('3xl')} {
    padding: 0;
  }

  ${media.up('5xl')} {
    flex-direction: row;
    padding: 96px 11.5%;
  }
`

const DefContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${media.up('3xl')} {
    flex-direction: row;
    padding: 32px 28px 72px 36px;
  }

  ${media.up('5xl')} {
    flex-direction: row;
    padding: 0;
  }
`

const LeftContainer = styled.div`
  display: flex;
  margin-bottom: 48px;

  ${media.up('3xl')} {
    margin-right: 36px;
    margin-bottom: 0;
  }

  ${media.up('5xl')} {
    margin-right: 128px;
  }
`

const ImageContainer = styled.div`
  margin: auto;

  img {
    border-radius: 24px 24px 0 0;
    width: 100%;
    height: 100%;
  }

  ${media.up('3xl')} {
    min-height: 546px;
    width: 330px;

    img {
      width: 100%;
      height: 100%;
    }

    ${media.up('5xl')} {
      min-height: 648px;
      width: 392px;

      img {
        width: 100%;
        height: 100%;
      }
    }
`

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${media.up('5xl')} {
    margin-top: 42px;
  }
`

const TitleDefinitionContainer = styled.div`
  margin: 0 0 24px 0;
`

const DescriptionDefinitionContainer = styled.div`
  margin: 0 0 24px 0;
`

const ListHeaderDefinitionContainer = styled.div`
  margin: 0 0 28px 0;
`

const ItemListContainer = styled.div`
  :last-child {
    padding: 0;
  }

  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 0 28px 0;

  svg {
    margin: 0 18px 0 0;
    width: 32px;
    height: 32px;
  }
`

export {
  Container,
  DefinitionContainer,
  DefContainer,
  LeftContainer,
  ImageContainer,
  RightContainer,
  TitleDefinitionContainer,
  DescriptionDefinitionContainer,
  ListHeaderDefinitionContainer,
  ItemListContainer,
}
